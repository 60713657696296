import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Languages & Technologies`}</h2>
    <br />
    <div className="language-and-techs">
  <h4>Backend</h4>
  <p className="tech-list">
    <strong>Dotnet Core</strong> (WebAPI, Dotnet SPA, MVC, and EF Core)
  </p>
  <p className="tech-list">
    <strong>Python</strong> (Django, WebScraping using phantomjs and chrome webdriver, MITM, and etc)
  </p>
  <p className="tech-list">
    <strong>NodeJS</strong> (Express, HapiJS, and NestJS)
  </p>
  
  <h4>Frontend framework and tools</h4>
  <p className="tech-list">
    Angular, React, TypeScript, Babel, D3, Gatsby, Redux, Reactive State management (NGRX and Akita), NextJs, GraphQL, React-Native, and Xamarin
  </p>
  <h4>Database</h4>
  <p className="tech-list">
    Microsoft SQL Server, NoSQL (MongoDB, CouchDB, DynamoDB), and PostgreSQL
  </p>
  <h4>Devops</h4>
  <p className="tech-list">
    Containerized Setup, Gitlab Devops, Octopus-Deploy
  </p>
  <p className="tech-list">
    Kubernetes (Deployment, Service discovery, Ingress Controller, Env vars/secrets, HPA), and Helm setup
  </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      