/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">
      <button
        sx={{
          variant: `buttons.toggle`,
          fontWeight: `semibold`,
          display: `block`,
          mx: `auto`,
          mb: 3
        }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
        className="material-button"
      >
        {isDark ? `Light` : `Dark`}
      </button>
      <br />
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
        }}
      >
        This site is powered by&nbsp;
        <a
          href="https://www.gatsbyjs.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
        Gatsby
        </a>&nbsp;and&nbsp;
        <a
          href="https://netlify.com"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >Netlify</a>
      </Flex>
    </Box>
  )
}

export default Footer
