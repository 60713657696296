import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch 🤙`}</h2>
    <p>{`If you have a project that you want to get started, think you need my help with something or just fancy saying hey, then get in touch `}<a parentName="p" {...{
        "href": "mailto:allanroy@balderama.net"
      }}>{`allanroy@balderama.net`}</a>{` .`}</p>
    <div classs="social-media">
  You can also DM me on 
  <a href="https://twitter.com/roybalderama" target="_blank" rel="noreferrer">{" "}twitter{" "}</a>{" "}or {" "}
  <a href="https://m.me/rybldrm" target="_blank" rel="noreferrer">
    Facebook
  </a>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      