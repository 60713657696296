import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>
  I am currently working as a Full-Stack Web Developer at <a href="https://synlab.com" target="_blank">SYNLAB International GmbH</a>, 
  with 9 years of professional working experience. My interests ranges from backend, front-end, devops to mobile applications.
    </p>
    <p>
  All my previous work history are on <a href="https://www.linkedin.com/in/allan-roy-balderama-b528b157/" target="_blank">LinkedIn</a>. Feel free to check it anytime 😎💻.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      