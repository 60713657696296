import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="hero">
  <img className="hero-image bouncy" src="/roybalderama.jpg" alt="Allan Roy Balderama" />
    </div>
    <span className="intro-title bouncy">
  <h2>Hi, I'm Allan Roy Balderama</h2>
    </span>
    <p className="intro-sub bouncy">Full-Stack Web Developer • <i>JavaScript</i> Junkie • Loves Front-End stuff, Adventure, Sunset 🌅 & Pizza 🍕</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      